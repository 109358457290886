import dayjs from 'dayjs';

const adventures = [
  {
    id: '1000',
    active: false,
    bonusRewardTrait: ['Treasure Box'],
    cooldown: 3,
    duration: 7,
    name: 'Royally Drunk in the Shitty Tavern',
    description: `When the mischievous Little Royals venture out of their castle for a taste of adventure, they find themselves in the notorious Shitty Tavern, bringing together the unlikely duo of the "Shitty Kitties", a gang of street-smart felines with a taste for adventure (and fishbones), and the "Little Royals,"  pampered heirs who decide to ditch their crowns for a night of mischief. When these two worlds collide at the infamous "Shitty Tavern," the result is a uproarious evening of catnip-laced chaos, unexpected dance-offs, and a quest for the elusive "Cat's Mead." As the Little Royals trade their crowns for questionable cocktails and the Shitty Kitties show off their party tricks, this unforgettable escapade teaches everyone that even royals can let their fur down and cats can have a royal sense of humor!`,
    endDate: dayjs('10/28/2023 12:00 pm EST').toDate(),
    env: 'none',
    image: '/adventures/little-royals.jpeg',
    rewards: 1,
    requirements: {
      level: 1,
      partySize: 2,
      collab: {
        name: 'Little Royal',
        addresses: ['GL6QISE6DYSBJGCZSK6AFTJBHYM2E6DTH47PIDDA4SL7WWHN4U7MJDYCGY'],
        reward: {
          id: 1181063592,
          name: 'Shitty Royals Treasure Box',
          image: '/boxes/little-royals.jpeg',
          token: {
            id: 1141491210,
            name: 'CROWN',
          },
        },
      },
    },
  },
  {
    id: '1001',
    active: true,
    bonusRewardTrait: ['Treasure Box', 'Legendary'],
    cooldown: 3,
    duration: 7,
    name: 'Brainstorm Sesh',
    description: `Spend a week trying to come up with Shitty Adventure ideas.`,
    endDate: dayjs('03/31/2025 12:00 pm EST').toDate(),
    env: 'prod',
    image: '/adventures/brainstorm-sesh.png',
    rewards: 1,
    requirements: {
      level: 4,
      partySize: 1,
    },
  },
  {
    id: '1002',
    active: false,
    bonusRewardTrait: ['Treasure Box'],
    cooldown: 3,
    duration: 7,
    name: 'Shitty Casino Heist',
    description: `You've been preparing all week for this heist, meticulously planning each step with crayon-drawn maps and a strategy devised from watching all of the Ocean's movies. The target: The Shitty Casino, famous not for its wealth, but because it's the only establishment in the city that still uses coins in its slot machines. A jackpot of quarters awaits you.`,
    endDate: dayjs('11/17/2023 12:00 pm EST').toDate(),
    env: 'none',
    image: '/adventures/casino-heist.png',
    rewards: 1,
    requirements: {
      level: 1,
      partySize: 2,
      collab: {
        name: 'Shitty City',
        addresses: ['SCDV2PGHKYVRC6IAUTFEQJPLQPL6D3WGBROGX7XF65SQLWUMW4SGEH4XEU'],
        blocks: ['Casino'],
        reward: {
          id: 1080000898,
          name: 'Shitty Treasure Box',
          image: '/boxes/shitty-treasure.png',
          token: {
            id: process.env.NEXT_PUBLIC_TREAT_TOKEN_ID,
            name: 'Shitty Treats',
          },
        },
      },
    },
  },
  {
    id: '1003',
    active: false,
    bonusRewardTrait: ['Treasure Box'],
    cooldown: 3,
    duration: 7,
    name: `Cruisin' and Boozin'`,
    description: `You and a Shitty friend are on a 7-day cruise filled with swimming, relaxing, cocktails, and so much food!`,
    endDate: dayjs('11/17/2023 12:00 pm EST').toDate(),
    env: 'none',
    image: '/adventures/cruising.png',
    rewards: 1,
    requirements: {
      level: 1,
      partySize: 2,
    },
  },
  {
    id: '1004',
    active: false,
    bonusRewardTrait: ['Treasure Box', 'Legendary'],
    cooldown: 3,
    duration: 7,
    name: `Pickle Fest`,
    description: `You got a job at the Pickle Fest this year for a week. All you have to do is wear a pickle costume and hand out pickles to people. Oh and you get to eat all the pickles you want!`,
    endDate: dayjs('09/1/2024 12:00 pm EST').toDate(),
    env: 'none',
    image: '/adventures/pickle-fest.png',
    rewards: 1,
    requirements: {
      level: 3,
      partySize: 1,
    },
  },
  {
    id: '1005',
    active: false,
    bonusRewardTrait: ['Treasure Box'],
    cooldown: 3,
    duration: 7,
    name: `Decorating for Halloween`,
    description: `You're having a Halloween party in just a week, and the house is begging for a supernatural transformation! Cobwebs have yet to drape the corners, and the pumpkins are still uncarved. Better get to work!`,
    endDate: dayjs('11/03/2024 12:00 pm EST').toDate(),
    env: 'none',
    image: '/adventures/halloween-decorating.png',
    rewards: 1,
    requirements: {
      level: 1,
      partySize: 2,
    },
  },
  {
    id: '1006',
    active: false,
    bonusRewardTrait: ['Treasure Box'],
    cooldown: 3,
    duration: 7,
    name: `Delivering Shitty Presents`,
    description: `You've been tasked with delivering Shitty Presents to all the good Shitty Kitties in the world.`,
    endDate: dayjs('01/15/2024 12:00 pm EST').toDate(),
    env: 'none',
    image: '/adventures/delivering-presents.png',
    rewards: 1,
    requirements: {
      level: 3,
      partySize: 1,
    },
  },
  {
    id: '1007',
    active: false,
    bonusRewardTrait: ['Treasure Box'],
    cooldown: 3,
    duration: 7,
    name: 'A Shitty Day at Sea',
    description: `The seas are rough out here and we lost the cargo. Help your Gully friends to recover their boxes. If you do, they will surely share the THICC booty!`,
    endDate: dayjs('10/31/2024 12:00 pm EST').toDate(),
    env: 'none',
    image: '/adventures/gully.png',
    rewards: 1,
    requirements: {
      level: 1,
      partySize: 2,
      collab: {
        name: 'GULLY',
        addresses: ['ZK7CZT7NZJT6G6HWTZ5VD5SLUSTJSVFA2DW2FMLFYZKM7SAFENR7QZE6KE'],
        reward: {
          id: 1259777828,
          name: 'Shitty Gully Treasure Box',
          image: '/boxes/gully.png',
          token: {
            id: 1194470385,
            name: 'THICC',
          },
        },
      },
    },
  },
  {
    id: '1008',
    active: false,
    bonusRewardTrait: ['Treasure Box'],
    cooldown: 3,
    duration: 7,
    name: `Encounter in Vellas Forest`,
    description: `In the eerie depths of the Vellas forest, trackers are following their Alglodie familiars toward unknown treasures. These genetically altered variants are the truffle pigs of their world, picking up scents from miles away and leading their masters to them. As they make their way to a clearing, a loud booming "meeeow" is heard, and out of the tall grass stands a bipedal cat holding what looks like a map. Without time to respond, the creature speaks, "Let's go on an adventure!"`,
    endDate: dayjs('02/01/2024 12:00 pm EST').toDate(),
    env: 'none',
    image: '/adventures/glodie.webp',
    rewards: 1,
    requirements: {
      level: 1,
      partySize: 2,
      collab: {
        name: 'Vellas/Alglodie',
        addresses: [
          'DFLVDXYFI7XUF5OUR3WX6LJQNO7XWP6FQ23GQK6C4EXEJ4SSYGXYEKOE7Y',
          'N7NVMEHWDSVS2NBUIYLUXKIQCWU33GE7OATAAOU3X23UCVB75WIZK5WFXA',
          'FFOZRNHDJO2ILF6EHTODHFLI6LAT5SOYJ2O5ZNJJ2IAOLZWWYNTOUPUDBA',
        ],
        reward: {
          id: 1267803421,
          name: 'Shitty Alglodie Treasure Box',
          image: '/boxes/glodie.webp',
          token: {
            id: 1042867088,
            name: 'WOOGIE',
          },
        },
      },
    },
  },
  {
    id: '1009',
    active: false,
    bonusRewardTrait: ['Treasure Box'],
    cooldown: 3,
    duration: 7,
    name: `Picnic at the Treehouse`,
    description: `Come join us at the Algo Treehouse. This adventure is the sort found on a Summer day during childhood: read a book, do some role playing, or get back on your sk8 board! Lots of laughs, good food, and strictly no screens allowed!`,
    endDate: dayjs('03/14/2024 12:00 pm EST').toDate(),
    env: 'none',
    image: '/adventures/treehouse.webp',
    rewards: 1,
    requirements: {
      level: 1,
      partySize: 2,
      collab: {
        name: 'Arcadian Tales/SK8 NFT/NFTales/Land of Alegra',
        addresses: [
          'SK3WSQES34T6IERZFHFISAWR5M37YSYXJZTCXFGAQCWAPOYQJET6ISVK3Q',
          'TALE7TBFEOCK4Q4M4QLYTPYTNTA2L7MYDY7IBYK3UOO4TIXH2YJBBS3EWU',
          'TALE54EO7RHDZ4C5S6VDPFS22STGBSI7KYS4K7YJUSHQYYGNCG7U7C4WCU',
          'RM5CBEYZIRAVBC3VZQFLNB5L2OB3W7ABOGP5Q25CMREPBZSREYKSZAWU7I',
          '3SJXP5JJWNC3V7OD2R5NGV55ATBWCWXVYA27HV2SXLWB6MNITIJP3VOJRI',
          '2ZOO6KV7LIDQCHLVKSVJN6LBF4JM4JC2WZC6QBI2JH4NQKLDFZ2XX4EY3E',
        ],
        reward: {
          id: 1400425406,
          name: 'Shitty Tales Treasure Box',
          image: '/boxes/treehouse.webp',
          token: {
            id: 403499324,
            name: 'Nexus',
          },
        },
      },
    },
  },
  {
    id: '1010',
    active: true,
    bonusRewardTrait: ['Treasure Box'],
    cooldown: 3,
    duration: 7,
    name: `The Shitty Olympics`,
    description: `For one week, you will compete in the Shitty Olympics. Events include the 100m dash, the 50m swim, and the 5m nap. Go for gold!`,
    endDate: dayjs('03/31/2025 12:00 pm EST').toDate(),
    env: 'prod',
    image: '/adventures/shitty-olympics.jpeg',
    rewardsGold: 1,
    requirements: {
      level: 5,
      partySize: 1,
    },
  },
  {
    id: '1011',
    active: false,
    bonusRewardTrait: ['Treasure Box'],
    cooldown: 3,
    duration: 7,
    name: `Shitty Baking Competition`,
    description: `You've been invited to participate in the Shitty Baking Competition. You have one week to bake the shittiest cake, pie, or cookies you can.`,
    endDate: dayjs('05/15/2024 12:00 pm EST').toDate(),
    env: 'none',
    image: '/adventures/shitty-baking.jpeg',
    rewards: 1,
    requirements: {
      level: 1,
      partySize: 1,
    },
  },
  {
    id: '1012',
    active: false,
    bonusRewardTrait: ['Treasure Box'],
    cooldown: 3,
    duration: 7,
    name: `Trip Around the Sun`,
    description: `Shitty Command has picked up a mysterious signal coming from somewhere near the sun. Your team will be arriving at the signal in one week. Prepare for anything.`,
    endDate: dayjs('05/15/2024 12:00 pm EST').toDate(),
    env: 'none',
    image: '/adventures/around-the-sun.jpeg',
    rewards: 1,
    requirements: {
      level: 1,
      partySize: 2,
    },
  },
  {
    id: '1013',
    active: false,
    bonusRewardTrait: ['Treasure Box', 'Legendary'],
    cooldown: 3,
    duration: 7,
    name: `Camping on the Lake`,
    description: `You and a friend will spend a week camping on the lake. Fishing, swimming, and hiking are all on the agenda.`,
    endDate: dayjs('06/17/2024 12:00 pm EST').toDate(),
    env: 'none',
    image: '/adventures/camping.png',
    rewards: 1,
    requirements: {
      level: 2,
      partySize: 2,
    },
  },
  {
    id: '1014',
    active: false,
    bonusRewardTrait: ['Treasure Box', 'Legendary'],
    cooldown: 3,
    duration: 7,
    name: `Stranded on a Deserted Island`,
    description: `You're stranded on a deserted island and only have enough beer for one week. Hopefully, you'll be rescued before it runs out.`,
    endDate: dayjs('06/17/2024 12:00 pm EST').toDate(),
    env: 'none',
    image: '/adventures/stranded.png',
    rewards: 1,
    requirements: {
      level: 1,
      partySize: 1,
    },
  },
  {
    id: '1015',
    active: false,
    bonusRewardTrait: ['Treasure Box', 'Legendary'],
    cooldown: 3,
    duration: 7,
    name: `Scuba Diving`,
    description: `Diving into the crystal clear waters of Shitty Lake, you'll search for rare fish and treasure.`,
    endDate: dayjs('06/17/2024 12:00 pm EST').toDate(),
    env: 'none',
    image: '/adventures/scuba-diving.png',
    rewards: 1,
    requirements: {
      level: 3,
      partySize: 1,
    },
  },
  {
    id: '1016',
    active: false,
    bonusRewardTrait: ['Treasure Box', 'Legendary'],
    cooldown: 3,
    duration: 7,
    name: `Portals`,
    description: `Mysterious portals have opened revealing kitties from other dimensions! You have one week to explore these new worlds and collect any treasures you can find.`,
    endDate: dayjs('07/15/2024 12:00 pm EST').toDate(),
    env: 'none',
    image: '/adventures/portals.png',
    rewardsGold: 1,
    requirements: {
      level: 1,
      partySize: 1,
      traits: ['Legendary'],
    },
  },
  {
    id: '1017',
    active: false,
    bonusRewardTrait: ['Treasure Box', 'Legendary'],
    cooldown: 3,
    duration: 7,
    name: `Shitty Gardening Club`,
    description: `Join the Shitty Gardening Club! Cultivate mushrooms, flowers, and other plants in the Shitty Garden.`,
    endDate: dayjs('07/15/2024 12:00 pm EST').toDate(),
    env: 'none',
    image: '/adventures/gardening.png',
    rewards: 1,
    requirements: {
      level: 1,
      partySize: 1,
    },
  },
  {
    id: '1018',
    active: false,
    bonusRewardTrait: ['Treasure Box', 'Legendary'],
    cooldown: 3,
    duration: 7,
    name: `Skiing in Aspen`,
    description: `"You french fried when you should have pizza'd. If you french fry when you should pizza, you're gonna have a bad time!"`,
    endDate: dayjs('07/15/2024 12:00 pm EST').toDate(),
    env: 'none',
    image: '/adventures/skiing.png',
    rewards: 1,
    requirements: {
      level: 1,
      partySize: 4,
    },
  },
  {
    id: '1019',
    active: false,
    bonusRewardTrait: ['Treasure Box', 'Legendary'],
    cooldown: 3,
    duration: 7,
    name: `The Lost Jungle City`,
    description: `You've been exploring the jungle for days and you think you're close to finding the Lost City. What sort of boxes, treats and other treasure awaits?`,
    endDate: dayjs('7/28/2024 12:00 pm EST').toDate(),
    env: 'none',
    image: '/adventures/lost-jungle-city.png',
    rewards: 1,
    requirements: {
      level: 1,
      partySize: 1,
    },
  },
  {
    id: '1020',
    active: false,
    bonusRewardTrait: ['Treasure Box', 'Legendary'],
    cooldown: 3,
    duration: 7,
    name: `Rocket to Shitty Station`,
    description: `Elon Shitty says we must colonize other planets. The first step to being multi-planet shitties is the Shitty Station, a city in space. You're one of the first engineers heading to the station to help build it. Strap in! 🚀`,
    endDate: dayjs('8/12/2024 12:00 pm EST').toDate(),
    env: 'none',
    image: '/adventures/flight-shitty-station.png',
    rewards: 1,
    requirements: {
      level: 3,
      partySize: 2,
      collab: {
        name: 'Shitty City',
        addresses: ['SCDV2PGHKYVRC6IAUTFEQJPLQPL6D3WGBROGX7XF65SQLWUMW4SGEH4XEU'],
        reward: {
          id: 1080000898,
          name: 'Shitty Treasure Box',
          image: '/boxes/shitty-treasure.png',
          token: {
            id: process.env.NEXT_PUBLIC_TREAT_TOKEN_ID,
            name: 'Shitty Treats',
          },
        },
      },
    },
  },
  {
    id: '1021',
    active: false,
    bonusRewardTrait: ['Treasure Box', 'Legendary', 'Puffy Winter Jacket', 'Skis'],
    cooldown: 3,
    duration: 7,
    name: `Solo Arctic Expedition`,
    description: `You're going on a solo expedition to a research city in the Arctic. The goal is to reach the city and collect as many treasures as you can. Be prepared for the cold!`,
    endDate: dayjs('9/12/2024 12:00 pm EST').toDate(),
    env: 'none',
    image: '/adventures/arctic.png',
    rewards: 1,
    requirements: {
      level: 4,
      partySize: 1,
    },
  },
  {
    id: '1022',
    active: false,
    bonusRewardTrait: ['Treasure Box', 'Legendary'],
    cooldown: 3,
    duration: 7,
    name: `Bear Market Party`,
    description: `Never stop partying`,
    endDate: dayjs('10/10/2024 12:00 pm EST').toDate(),
    env: 'none',
    image: '/adventures/party.webp',
    rewards: 1,
    requirements: {
      level: 1,
      partySize: 2,
      collab: {
        name: 'Lundi/State Pooof',
        addresses: [
          'PFDZQWMRT2KTJTB3VUGDOILNCNSB63ILZL4XMBHYECBOV24LGTID4YRFPM',
          '7PVEEP2CS77VJEYZGW2IIGZ63P5CO557XRNKBRPTIREKLET7A4G62W4CQA',
          'TXZ3AKZLIKNNT3OBQOMTSYWC7AK7CIVSZZIDCSTONXNTX44LQIRU6ELFDA',
          'UB6E3ACF7MHSAY5LRRNUHMW55IAQGGWOVADTVDNMF7JL7CA25GO7V4F2MI',
          'DAG3Y4DBSACNCBF26EAV3LZOQILCK4NH6DOKAWHMK3WL2ZLID5U7IWX7VU',
          'DYJ7A6RBKMDVYXMQVPIE3WRDPLWSRLSSTKTC53EWRWYM3IAIH423IDU6AI',
          'MPJKTLHWSBDWBBOQKJVJTMPXQ7PS7CJMIJYAUMMMG5LT6QO7I3NHKRMTCA',
        ],
        reward: {
          id: 1080001698,
          name: 'Shitty Pooof Treasure Box',
          image: '/boxes/poof-treasure.png',
          token: {
            id: 658399558,
            name: 'Pooof',
          },
        },
      },
    },
  },
  {
    id: '1023',
    active: false,
    bonusRewardTrait: ['Treasure Box', 'Legendary'],
    cooldown: 3,
    duration: 7,
    name: `Shitty Fight Club`,
    description: `The first rule of Shitty Fight Club is do not talk about Shitty Fight Club.`,
    endDate: dayjs('10/30/2024 12:00 pm EST').toDate(),
    env: 'none',
    image: '/adventures/fight-club.png',
    rewards: 1,
    requirements: {
      level: 1,
      partySize: 1,
    },
  },
  {
    id: '1024',
    active: false,
    bonusRewardTrait: ['Treasure Box', 'Legendary'],
    cooldown: 3,
    duration: 7,
    name: `Shitty Thanksgiving`,
    description: `You're invited to a neighborhood Thanksgiving feast. There will be turkey, stuffing, mashed potatoes, and plenty of treats. Have some lunch and enjoy the holiday!`,
    endDate: dayjs('12/31/2024 12:00 pm EST').toDate(),
    env: 'none',
    image: '/adventures/thanksgiving.png',
    rewards: 1,
    requirements: {
      level: 1,
      partySize: 4,
    },
  },
];

export default adventures;
