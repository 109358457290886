const treasureBoxes = [
  {
    id: 809610090,
    name: 'Shitty Treasure Box (Old)',
    image: '/boxes/shitty-treasure-old.webp',
    contents: {
      name: 'Shitty Treats',
      token: 796490640,
      tokenAmounts: [30, 125],
      vmPullChance: 0.04,
    },
  },
  {
    id: 1080000898,
    name: 'Shitty Treasure Box (New)',
    image: '/boxes/shitty-treasure.png',
    contents: {
      name: 'Shitty Treats',
      token: 796490640,
      tokenAmounts: [200, 400],
      vmPullChance: 0.05,
    },
  },
  {
    id: parseInt(process.env.NEXT_PUBLIC_GOLDEN_TREASURE_ID),
    name: 'Golden Treasure Box',
    image: '/boxes/golden-treasure.png',
    contents: {
      name: 'Shitty Treats',
      token: 796490640,
      tokenAmounts: [400, 600],
      vmPullChance: 0.25,
    },
  },
  {
    id: 1080001698,
    name: 'Shitty Pooof Treasure Box',
    image: '/boxes/poof-treasure.png',
    contents: {
      name: 'Pooof',
      token: 658399558,
      tokenAmounts: [100, 250],
      vmPullChance: 0.04,
    },
  },
  {
    id: 1080002810,
    name: 'Shitty Skuli Frens Treasure Box',
    image: '/boxes/skuli-treasure.png',
    contents: {
      name: 'skuli snacks',
      token: 704036829,
      tokenAmounts: [25, 25],
      vmPullChance: 0.04,
    },
  },
  {
    id: 1086653827,
    name: 'Shitty Tucan Treasure Box',
    image: '/boxes/tucan-treasure.png',
    contents: {
      name: 'BPM',
      token: 1056720965,
      tokenAmounts: [100, 500],
      vmPullChance: 0.04,
    },
  },
  {
    id: 1095156984,
    name: 'Shitty BBB Treasure Box',
    image: '/boxes/bbb-treasure.jpeg',
    contents: {
      name: 'Feathers',
      token: 1091143717,
      tokenAmounts: [100, 100],
      vmPullChance: 0.04,
    },
  },
  {
    id: 1095160759,
    name: `Shitty MembersOnly Treasure Box`,
    image: '/boxes/members-only-treasure.png',
    contents: {
      name: 'MEMO',
      token: 885835936,
      tokenAmounts: [5, 20],
      divisible: 1000,
      vmPullChance: 0.04,
    },
  },
  {
    id: 1100490573,
    name: `Shitty Goodnight Treasure Box`,
    image: '/boxes/lord-goodnight-treasure.png',
    contents: {
      name: 'Shitty Treats',
      token: 796490640,
      tokenAmounts: [30, 125],
      vmPullChance: 0.04,
    },
  },
  {
    id: 1100522996,
    name: `Shitty ARG Treasure Box`,
    image: '/boxes/arg-treasure.jpeg',
    contents: {
      name: 'ADD',
      token: 925648644,
      tokenAmounts: [50, 50],
      vmPullChance: 0.04,
    },
  },
  {
    id: 1111240145,
    name: `Shitty JoeJoBabbit Treasure Box`,
    image: '/boxes/joe-jo-treasure.jpeg',
    contents: {
      name: 'BBUCKS',
      token: 1086216637,
      tokenAmounts: [10, 25],
      vmPullChance: 0.04,
    },
  },
  {
    id: 1111240946,
    name: `Shitty NOOT Treasure Box`,
    image: '/boxes/nooter-treasure.png',
    contents: {
      name: 'ANC',
      token: 933539255,
      tokenAmounts: [35, 35],
      vmPullChance: 0.04,
    },
  },
  {
    id: 1128937971,
    name: `Shitty Bambino Treasure Box`,
    image: '/boxes/bambino.png',
    contents: {
      name: 'BAMBI',
      token: 1034854939,
      tokenAmounts: [10, 10],
      vmPullChance: 0.04,
    },
  },
  {
    id: 1130167920,
    name: `Shitty PIXELheads Treasure Box`,
    image: '/boxes/pixelheads.png',
    contents: {
      name: 'Yarn',
      token: 878951062,
      tokenAmounts: [100, 150],
      vmPullChance: 0.04,
    },
  },
  {
    id: 1145120624,
    name: `Shitty Donkey DOA Treasure Box`,
    image: '/boxes/donkey.png',
    contents: {
      name: 'Shitty Treats',
      token: 796490640,
      tokenAmounts: [30, 125],
      vmPullChance: 0.04,
    },
  },
  {
    id: 1151238932,
    name: `Shitty Heepow Treasure Box`,
    image: '/boxes/heepow.jpeg',
    contents: {
      name: 'Grass',
      token: 765171236,
      tokenAmounts: [100, 100],
      vmPullChance: 0.04,
      divisible: 100000000,
    },
  },
  {
    id: 1181063592,
    name: 'Shitty Royal Treasure Box',
    image: '/boxes/little-royals.jpeg',
    contents: {
      name: 'CROWN',
      token: 1141491210,
      tokenAmounts: [50, 100],
      vmPullChance: 0.04,
    },
  },
  {
    id: 1259777828,
    name: 'Shitty Gully Treasure Box',
    image: '/boxes/gully.png',
    contents: {
      name: 'THICC',
      token: 1194470385,
      tokenAmounts: [50, 50],
      vmPullChance: 0.04,
    },
  },
  {
    id: 1267803421,
    name: 'Shitty Alglodie Treasure Box',
    image: '/boxes/glodie.webp',
    contents: {
      name: 'WOOGIE',
      token: 1042867088,
      tokenAmounts: [50, 50],
      vmPullChance: 0.04,
    },
  },
  {
    id: 1400425406,
    name: 'Shitty Tales Treasure Box',
    image: '/boxes/treehouse.webp',
    contents: {
      name: 'Shitty Treats',
      token: 796490640,
      tokenAmounts: [30, 125],
      vmPullChance: 0.04,
    },
  },
];

export default treasureBoxes;
